<template>
    <div>
		<b-modal ref="modal_duplicate_quotation" size="lg" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t('invoice.devis.dupliquer') }}
				</template>
			</template>

			<div>
				<label>{{ $t('tiers.tiers') }}</label>
				<SearchTiers 
					:tiers_selected.sync="tiers"
				/>
				<div class="text-center">
					<b-button variant="primary" class="mt-3" @click="duplicate">
						<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'clone']" /> {{ $t('action.dupliquer') }}
					</b-button>
				</div>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
	import Accounting from '@/mixins/Accounting.js'
	import Contact from '@/mixins/Contact.js'
	import Quotation from '@/mixins/Quotation.js'
	import Model from "@/mixins/Model.js"

	export default {
		name: "ModalDuplicateQuotation",
		mixins: [Quotation],
		data () {
			return {
				processing: false,
				quotation_id: null,
				tiers: null
			}
		},
		methods: {
			openModal(quotation_id) {
				this.quotation_id = quotation_id
				this.$refs.modal_duplicate_quotation.show()
			},
			closeModal() {
				this.tiers = null
				this.$refs.modal_duplicate_quotation.hide()
			},
			async duplicate() {
				if(!this.tiers || !this.tiers.tiers_id) {
					return false
				}
				this.processing = true
				await this.duplicateQuotation(this.quotation_id, this.tiers.tiers_id)
				this.processing = false
				this.closeModal()
				this.$emit('submit')
			}
		},
		components: {
			SearchTiers : () => import('@/components/Contract/SearchTiers')
		}
	}
</script>